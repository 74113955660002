import {
  CURRENCY_CODE,
  CURRENCY_PREFIXES,
  CURRENCY_SUFFIXES
} from '@/utils/constants'

export const exchange = (code = 'US', amount = 0) => {
  const currency = CURRENCY_CODE[code] || 'USD'
  const currencyPrefix = CURRENCY_PREFIXES[currency] || ''
  const currencySuffix = CURRENCY_SUFFIXES[currency] || ''

  return `${currencyPrefix}${amount}${currencySuffix}`
}

const action = {
  exchange
}

export default action
