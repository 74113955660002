import { Suspense, lazy, useEffect } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import ScrollToTop from 'react-router-scroll-top'
import { useTranslation } from 'react-i18next'
import { getUserIP } from '@/apis/cloudfare'
import { ConfigProvider, notification } from 'antd'
import Theme from '@/containers/Theme'
import { detectIE } from 'utils/tools'
import './i18n'
import './App.less'

import RoutePrivate from '@/containers/RoutePrivate'
import RoutePublic from '@/containers/RoutePublic'

import Loading from '@/components/Loading'
import ModalForceTasterOut from '@/components/ModalForceTasterOut'
import Error404 from '@/components/Error/404'
import useDirection from '@/hooks/useDirection'

const Welcome = lazy(() => import('@/pages/Welcome'))
const Signup = lazy(() => import('@/pages/Signup'))
const SignupWalkin = lazy(() => import('@/pages/SignupWalkin'))
const Signin = lazy(() => import('@/pages/Signin'))
const SigninWithFacebook = lazy(() => import('@/pages/SigninWithFacebook'))
const SigninWithGoogle = lazy(() => import('@/pages/SigninWithGoogle'))
const SigninWithToken = lazy(() => import('@/pages/SigninWithToken'))
const ForgotPassword = lazy(() => import('@/pages/ForgotPassword'))
const ResetPassword = lazy(() => import('@/pages/ResetPassword'))
const ResendVerificationEmail = lazy(() => import('@/pages/ResendVerificationEmail'))
const VerifyEmail = lazy(() => import('@/pages/VerifyEmail'))
const ConnectWithSocial = lazy(() => import('@/pages/ConnectWithSocial'))
const TasterDashboard = lazy(() => import('@/pages/TasterDashboard'))
const Profile = lazy(() => import('@/pages/Profile'))
const Impersonate = lazy(() => import('@/pages/Impersonate'))
const SSOIdentify = lazy(() => import('@/pages/SSOIdentify'))
const Survey = lazy(() => import('@/pages/Survey'))
const ShareSurvey = lazy(() => import('@/pages/ShareSurvey'))
const QrRedirect = lazy(() => import('@/pages/QrRedirect'))
const NotVerified = lazy(() => import('@/pages/NotVerified'))
const Under18 = lazy(() => import('@/pages/Under18'))
const CodeExpired = lazy(() => import('@/pages/CodeExpired'))
const IEWarning = lazy(() => import('@/pages/IEWarning'))
const WalkinLanding = lazy(()=> import('@/pages/WalkinLanding'))

const App = () => {
  const { t } = useTranslation()
  const [direction] = useDirection()

  useEffect(() => {
    const isIE = detectIE()

    if (isIE && window.location.pathname !== '/improve-your-browser') {
      window.location.replace('/improve-your-browser')
    }

    getUserIP()
  }, [])

  useEffect(() => {
    const splashScreen = document.querySelector('#splashscreen')
    if (splashScreen) {
      splashScreen.classList.add('hidden')
    }
  }, [])

  useEffect(() => {
    const onlineFunction = () => {
      notification.destroy()
      notification.success({
        message: t('internet.online'),
        placement: 'bottomLeft'
      })
    }

    const offlineFunction = () => {
      notification.destroy()
      notification.error({
        duration: 0,
        message: t('internet.offline'),
        placement: 'bottomLeft'
      })
    }

    window.addEventListener('online', onlineFunction)
    window.addEventListener('offline', offlineFunction)

    return () => {
      window.removeEventListener('online', onlineFunction)
      window.removeEventListener('offline', offlineFunction)
    }
  }, [])

  return (
    <ConfigProvider direction={direction}>
      <Router>
      <ScrollToTop>
        <Theme>
          <Suspense fallback={<Loading />}>
            <Switch>
              <RoutePublic exact path='/'>
                <Welcome />
              </RoutePublic>

              <RoutePublic path='/sign-up'>
                <Signup />
              </RoutePublic>

              <RoutePublic path='/sign-up-walkin'>
                <SignupWalkin />
              </RoutePublic>

              <RoutePublic path='/sign-in'>
                <Signin />
              </RoutePublic>

              <RoutePublic path='/forgot-password'>
                <ForgotPassword />
              </RoutePublic>

              <RoutePublic path='/resend-verification-email'>
                <ResendVerificationEmail />
              </RoutePublic>

              <RoutePublic path='/onboarding/reset-password'>
                <ResetPassword />
              </RoutePublic>

              <RoutePublic path='/verify-email'>
                <VerifyEmail />
              </RoutePublic>

              <RoutePublic path='/requirement-information'>
                <ConnectWithSocial />
              </RoutePublic>

              <RoutePrivate path='/dashboard'>
                <TasterDashboard />
              </RoutePrivate>

              <RoutePrivate path='/profile'>
                <Profile />
              </RoutePrivate>

              <RoutePublic path='/walkin-landing/:surveyId'>
                <WalkinLanding />
              </RoutePublic>

              <Route path='/impersonate' component={Impersonate} />

              <Route path='/qr-code/:id' component={QrRedirect} />

              <Route path='/signin-with-token' component={SigninWithToken} />

              <Route path='/sso/identify' component={SSOIdentify} />

              <Route
                path='/signin-with-facebook'
                component={SigninWithFacebook}
              />

              <Route path='/signin-with-google' component={SigninWithGoogle} />

              <Route path='/survey/:surveyId' component={Survey} />

              <Route path='/share/:uniqueName' component={ShareSurvey} />

              <Route path='/not-verified' component={NotVerified} />

              <Route path='/under-18' component={Under18} />

              <Route path='/code-expired' component={CodeExpired} />

              <Route path='/improve-your-browser' component={IEWarning} />

              <Route path='*' component={Error404} />
            </Switch>
          </Suspense>

          <ModalForceTasterOut />
        </Theme>
      </ScrollToTop>
      </Router>
    </ConfigProvider>
  )
}

export default App
