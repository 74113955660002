import arEG from 'antd/lib/locale/ar_EG'
import deDE from 'antd/lib/locale/de_DE'
import enUS from 'antd/lib/locale/en_US'
import esES from 'antd/lib/locale/es_ES'
import frFR from 'antd/lib/locale/fr_FR'
import heIL from 'antd/lib/locale/he_IL'
import idID from 'antd/lib/locale/id_ID'
import itIT from 'antd/lib/locale/it_IT'
import jaJP from 'antd/lib/locale/ja_JP'
import koKR from 'antd/lib/locale/ko_KR'
import plPL from 'antd/lib/locale/pl_PL'
import ptBR from 'antd/lib/locale/pt_BR'
import ptPT from 'antd/lib/locale/pt_PT'
import roRO from 'antd/lib/locale/ro_RO'
import ruRU from 'antd/lib/locale/ru_RU'
import svSE from 'antd/lib/locale/sv_SE'
import thTH from 'antd/lib/locale/th_TH'
import trTR from 'antd/lib/locale/tr_TR'
import viVN from 'antd/lib/locale/vi_VN'
import zhCN from 'antd/lib/locale/zh_CN'
import msMY from 'antd/lib/locale/ms_MY'
import nlNL from 'antd/lib/locale/nl_NL'

import 'moment/locale/de'
import 'moment/locale/es'
import 'moment/locale/fr'
import 'moment/locale/it'
import 'moment/locale/ja'
import 'moment/locale/ko'
import 'moment/locale/he'
import 'moment/locale/pl'
import 'moment/locale/pt'
import 'moment/locale/pt-br'
import 'moment/locale/ru'
import 'moment/locale/sv'
import 'moment/locale/th'
import 'moment/locale/ro'
import 'moment/locale/tr'
import 'moment/locale/zh-cn'
import 'moment/locale/vi'
import 'moment/locale/id'
import 'moment/locale/ar'
import 'moment/locale/ur'
import 'moment/locale/ms-my'

import internationalSrc from '@/assets/images/international.svg'
import srcUS from '@/assets/images/flags/us.svg'
import srcCN from '@/assets/images/flags/cn.svg'
import srcPH from '@/assets/images/flags/ph.svg'
import srcFR from '@/assets/images/flags/fr.svg'
import srcDE from '@/assets/images/flags/de.svg'
import srcIT from '@/assets/images/flags/it.svg'
import srcJP from '@/assets/images/flags/jp.svg'
import srcKR from '@/assets/images/flags/kr.svg'
import srcPL from '@/assets/images/flags/pl.svg'
import srcPT from '@/assets/images/flags/pt.svg'
import srcBR from '@/assets/images/flags/br.svg'
import srcRU from '@/assets/images/flags/ru.svg'
import srcES from '@/assets/images/flags/es.svg'
import srcSE from '@/assets/images/flags/se.svg'
import srcTH from '@/assets/images/flags/th.svg'
import srcTR from '@/assets/images/flags/tr.svg'
import srcRO from '@/assets/images/flags/ro.svg'
import srcPK from '@/assets/images/flags/pk.svg'
import srcIL from '@/assets/images/flags/il.svg'
import srcVN from '@/assets/images/flags/vn.svg'
import srcID from '@/assets/images/flags/id.svg'
import srcEG from '@/assets/images/flags/eg.svg'
import srcAM from '@/assets/images/flags/am.svg'
import srcMY from '@/assets/images/flags/my.svg'
import srcNL from '@/assets/images/flags/nl.png'

export const LANGUAGES = [
  {
    value: 'en',
    src: srcUS
  },
  {
    value: 'ar',
    src: internationalSrc
  },
  {
    value: 'ar_EG',
    src: srcEG
  },
  {
    value: 'zh_CN',
    src: srcCN
  },
  {
    value: 'nl_NL',
    src: srcNL
  },
  {
    value: 'fil',
    src: srcPH
  },
  {
    value: 'fr',
    src: srcFR
  },
  {
    value: 'de',
    src: srcDE
  },
  {
    value: 'he_IL',
    src: srcIL
  },
  {
    value: 'id',
    src: srcID
  },
  {
    value: 'it_IT',
    src: srcIT
  },
  {
    value: 'ja',
    src: srcJP
  },
  {
    value: 'ko',
    src: srcKR
  },
  {
    value: 'ms_MY',
    src: srcMY
  },
  {
    value: 'pl',
    src: srcPL
  },
  {
    value: 'pt',
    src: srcPT
  },
  {
    value: 'pt_BR',
    src: srcBR
  },
  {
    value: 'ru_RU',
    src: srcRU
  },
  {
    value: 'es',
    src: srcES
  },
  {
    value: 'es_419',
    src: internationalSrc
  },
  {
    value: 'ro',
    src: srcRO
  },
  {
    value: 'sv',
    src: srcSE
  },
  {
    value: 'th',
    src: srcTH
  },
  {
    value: 'tr',
    src: srcTR
  },
  {
    value: 'ur_PK',
    src: srcPK
  },
  {
    value: 'am',
    src: srcAM
  },
  {
    value: 'vi',
    src: srcVN
  }
]

export const LANGUAGE_BY_COUNTRIES = {
  AR: ['en', 'es_419'],
  BR: ['en', 'pt_BR'],
  ET: ['en', 'am'],
  GN: ['en', 'fr'],
  CI: ['en', 'fr'],
  SN: ['en', 'fr'],
  CA: ['en', 'fr'],
  CL: ['en', 'es_419'],
  CN: ['en', 'zh_CN'],
  CO: ['en', 'es_419'],
  EG: ['en', 'ar_EG'],
  FR: ['en', 'fr'],
  DE: ['en', 'de'],
  ID: ['en', 'id'],
  IL: ['en', 'he_IL'],
  IT: ['en', 'it_IT'],
  JP: ['en', 'ja'],
  KR: ['en', 'ko'],
  MX: ['en', 'es', 'es_419'],
  MA: ['en', 'ar'],
  PK: ['en', 'ur_PK'],
  PE: ['en', 'es_419'],
  PH: ['en', 'fil'],
  PL: ['en', 'pl'],
  RO: ['en', 'ro'],
  RU: ['en', 'ru_RU'],
  SG: ['en', 'zh_CN'],
  SE: ['en', 'sv'],
  //CH: ['en', 'fr', 'de', 'it_IT'],
  TH: ['en', 'th'],
  TR: ['en', 'tr'],
  AE: ['en', 'ar'],
  ES: ['en', 'es'],
  VN: ['en', 'vi'],
  MY: ['en', 'ms_MY'],
  NL: ['en', 'nl_NL']
}

export const HASHMAP_LANGUAGE_CLIENT = {
  de: 'de',
  en: 'en',
  es: 'es',
  es_419: 'es_419',
  fr: 'fr',
  it_IT: 'it_IT',
  it: 'it_IT',
  ja: 'ja',
  ko: 'ko',
  kr: 'ko',
  ur_PK: 'ur_PK',
  ur: 'ur_PK',
  ar: 'ar',
  he_IL: 'he_IL',
  pl: 'pl',
  pt: 'pt',
  pt_BR: 'pt_BR',
  ru_RU: 'ru_RU',
  ru: 'ru_RU',
  sv: 'sv',
  se: 'sv',
  fil: 'fil',
  th: 'th',
  ro: 'ro',
  tr: 'tr',
  zh_CN: 'zh_CN',
  cn: 'zh_CN',
  vi: 'vi',
  id: 'id',
  am: 'am',
  ar_EG: 'ar_EG',
  ms_MY: 'ms_MY',
  nl_NL: 'nl_NL'
}

export const HASHMAP_LANGUAGE_SERVER = {
  de: 'de',
  en: 'en',
  es: 'es',
  es_419: 'es_419',
  fr: 'fr',
  it_IT: 'it',
  it: 'it',
  ja: 'ja',
  ko: 'kr',
  ur_PK: 'ur',
  ar: 'ar',
  he_IL: 'he_IL',
  pl: 'pl',
  pt: 'pt',
  pt_BR: 'pt_BR',
  ru_RU: 'ru',
  sv: 'se',
  fil: 'fil',
  th: 'th',
  ro: 'ro',
  tr: 'tr',
  zh_CN: 'cn',
  vi: 'vi',
  id: 'id',
  am: 'am',
  ar_EG: 'ar_EG',
  ms_MY: 'ms_MY',
  nl_NL: 'nl_NL'
}

export const HASHMAP_LANGUAGE_RECAPTCHA = {
  de: 'de',
  en: 'en',
  es: 'es',
  es_419: 'es-419',
  fr: 'fr',
  it_IT: 'it',
  it: 'it',
  ja: 'ja',
  ko: 'ko',
  ur_PK: 'ur',
  ar: 'ar',
  he_IL: 'iw',
  pl: 'pl',
  pt: 'pt',
  pt_BR: 'pt-BR',
  ru_RU: 'ru',
  sv: 'sv',
  fil: 'fil',
  th: 'th',
  ro: 'ro',
  tr: 'tr',
  zh_CN: 'zh-CN',
  vi: 'vi',
  id: 'id',
  am: 'am',
  ar_EG: 'ar_EG',
  ms_MY: 'ms_MY',
  nl_NL: 'nl_NL'
}

export const LANGUAGE_DIRECTION = {
  de: 'ltr',
  en: 'ltr',
  es: 'ltr',
  fr: 'ltr',
  it_IT: 'ltr',
  it: 'ltr',
  ja: 'ltr',
  ko: 'ltr',
  ur_PK: 'rtl',
  ar: 'rtl',
  he_IL: 'rtl',
  pl: 'ltr',
  pt: 'ltr',
  pt_BR: 'ltr',
  ru_RU: 'ltr',
  sv: 'ltr',
  fil: 'ltr',
  th: 'ltr',
  ro: 'ltr',
  tr: 'ltr',
  zh_CN: 'ltr',
  vi: 'ltr',
  id: 'ltr',
  am: 'ltr',
  ar_EG: 'rtl',
  ms_MY: 'ltr',
  nl_NL: 'ltr'
}

export const ANT_LOCALE = {
  de: deDE,
  en: enUS,
  es: esES,
  fr: frFR,
  it_IT: itIT,
  it: itIT,
  ja: jaJP,
  ko: koKR,
  he_IL: heIL,
  pl: plPL,
  pt: ptPT,
  pt_BR: ptBR,
  ru_RU: ruRU,
  sv: svSE,
  th: thTH,
  ro: roRO,
  tr: trTR,
  zh_CN: zhCN,
  vi: viVN,
  id: idID,
  ar_EG: arEG,
  ms_MY: msMY,
  nl_NL: nlNL
}

export const MOMENT_LOCALE = {
  de: 'de',
  en: 'en',
  es: 'es',
  fr: 'fr',
  it_IT: 'it',
  it: 'it',
  ja: 'ja',
  ko: 'ko',
  he_IL: 'he',
  pl: 'pl',
  pt: 'pt',
  pt_BR: 'pt-br',
  ru_RU: 'ru',
  sv: 'sv',
  th: 'th',
  ro: 'ro',
  tr: 'tr',
  zh_CN: 'zh-cn',
  vi: 'vi',
  id: 'id',
  ar: 'ar',
  am: 'am',
  ur_PK: 'ur',
  ms_MY: 'ms-my',
  nl_NL: 'nl-nl'
}
